/* You can add global styles to this file, and also import other style files */
@use './app/theme/m3-theme';

@import './app/theme/styles-set2.scss';
//@import "@ctrl/ngx-emoji-mart/picker";

@import './app/styles/google-icons.scss';
@import './assets/fonts/fonts.css';

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

// $theme: mat.define-theme((
//       typography: (
//         brand-family: Open Sans,
//         plain-family: Wingdings,
//         bold-weight: 300,
//         medium-weight: 200,
//         regular-weight: 100,
//       )
// ));


body {
  --primary-color: #3fa9f5;
  --primary-lighter-color: #c5e5fc;
  --primary-darker-color: #298ff0;

}  

body {
  --accent-color: #666666;
  --accent-lighter-color: #d1d1d1;
  --accent-darker-color: #494949;

}   

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;

}   

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.dialogbox {
  height: 500px;
  padding: 10px;
  margin: 10px;
}