
// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';  
  .mat-badge-content {
    font-family: 'Roboto';
  }
}



.listbox {
  overflow: hidden;
  font-size: 8pt;
  position: relative;
  display: inline-block;
  width: 98%;
  height: 20px;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  margin: 0px;
  padding: 3px;
  // background-color: var(--primary-color);
  border: 2px solid var(--primary-color);

  opacity: 0.8;
}

.boxnewcontacts {
  @extend .listbox;
  height: 40px;
  width: 100%;
}

.listbox::after {
  content: "";
  // border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.listbox:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  background-color: var(--primary-color);
  color: white;
}

.selected {
  font-weight: 600;
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  background-color: var(--primary-color);
  color: white;
}

.listbox:hover::after {
  opacity: 1;
}

.listbox label {
  overflow: hidden;
}


.avatar{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.companylogo {
  width: 54px;
  height: 40px;
  //border-radius: 50%;
  object-fit: cover;
}



.home-wrapper{
  position: relative;
}

.relations {
    height: 100%;
    width: 100%;
  }

.tabcontainer {
  overscroll-behavior: contain;
    // height: 1400px;
    // min-height: 1000px;
    // width: 100%;
   }


.snow {
  width: 4px;
  height: 4px; 
  background-color: white;
  border-radius: 50%;
  position: absolute;
  border-radius: 50%;
  box-shadow: 2px 2px 4px 4px #fcfcfc;
}

.celebrations {
  width: 8px;
  height: 30px; 
  position: absolute;
}


.leaves1 {
  width: 20px;
  height: 20px; 
  position: absolute;
  background: url(../../assets/AUTUMNLEAF-1.png);
  background-size: 100% 100%;
}

.leaves2 {
  width: 35px;
  height: 35px; 
  position: absolute;
  background: url(../../assets/AUTUMNLEAF-12.png);
  background-size: 100% 100%;
}

.leaves3 {
  width: 35px;
  height: 35px; 
  position: absolute;
  background: url(../../assets/AUTUMNLEAF-14.png);
  background-size: 100% 100%;
}

.leaves3 {
  width: 35px;
  height: 35px; 
  position: absolute;
  background: url(../../assets/AUTUMNLEAF-13.png);
  background-size: 100% 100%;
}

.flies {
  height: 1px;
	width: 1px;
  background: #613400d3;
  border-radius: 50%;
  box-shadow: 1px 1px 2px 2px #613400;
}

.starbackground {
  background: rgb(9,76,114);
  background: -webkit-radial-gradient(bottom, ellipse cover,  rgb(9,76,114) 0%,rgb(1,41,63) 100%);
  background: -webkit-radial-gradient(ellipse at bottom, rgb(9,76,114) 0%, rgb(1,41,63) 100%);
  background: -webkit-radial-gradient(bottom, ellipse, rgb(9,76,114) 0%, rgb(1,41,63) 100%);
  background: radial-gradient(ellipse at bottom,  rgb(9,76,114) 0%,rgb(1,41,63) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#094c72', endColorstr='#01293f',GradientType=1 );
}


.stars {
	height: 2px;
	width: 2px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 1px gainsboro;
  z-index: -1;
}

// .moon {
//   width: 150px;
//   height: 150px;
//   background: #c7cbd0;
//   border-radius: 50%;
//   box-shadow: inset -25px 0px 0 0px #9098a1;
//   position: absolute;
// }


// .limoon1 {
//   left: 45px;
//   top: 50px;
//   width: 50px;
//   height: 50px;
//   box-shadow: inset 6px -2px 0 0px #414043;
//   position: absolute;
//   border-radius: 50%;
//   background: #737277;
// }
// .limoon2  {
//   left: 19px;
//   top: 30px;
//   width: 50px;
//   height: 50px;
//   box-shadow: inset 6px -2px 0 0px #414043;
//   border-radius: 50%;
//   position: absolute;
//   background: #737277;
// }
// .limoon2  {
//   left: 70px;
//   top: 50px;
//   width: 25px;
//   height: 25px;
//   box-shadow: inset 3px -1px 0 0px #414043;
//   position: absolute;
//   border-radius: 50%;
//   background: #737277;
// }
// .limoon3  {
//   left: 100px;
//   top: 100px;
//   width: 25px;
//   height: 25px;
//   box-shadow: inset 3px -1px 0 0px #414043;
//   position: absolute;
//   border-radius: 50%;
//   background: #737277;
// }
// .limoon4 {
//   left: 50px;
//   top: 120px;
//   width: 12.5px;
//   height: 12.5px;
//   box-shadow: inset 2.4px -0.8px 0 0px #414043;
//   position: absolute;
//   border-radius: 50%;
//   background: #737277;
// }
// .limoon5 {
//   left: 87.5px;
//   top: 16px;
//   width: 12.5px;
//   height: 12.5px;
//   box-shadow: inset 2.4px -0.8px 0 0px #414043;
//   position: absolute;
//   border-radius: 50%;
//   background: #737277;
// }
// .limoon6  {
//   left: 114px;
//   top: 80px;
//   width: 12.5px;
//   height: 12.5px;
//   box-shadow: inset 2.4px -0.8px 0 0px #414043;
//   position: absolute;
//   border-radius: 50%;
//   background: #737277;
// }
// .limoon7  {
//   left: 130px;
//   top: 100px;
//   width: 12.5px;
//   height: 12.5px;
//   box-shadow: inset 2.4px -0.8px 0 0px #414043;
//   position: absolute;
//   border-radius: 50%;
//   background: #737277;
// }

.half-circle {
  top: -350px;
  width: 400px;
  height: 400px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: gray;
  position: absolute;
}

.half-circle-light {
  top: -350px;
  width: 400px;
  height: 400px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: lightgray;
  position: absolute;
}

.half-circle-dark {
  top: -350px;
  width: 400px;
  height: 400px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: darkgray;
  position: absolute;
}


.rain {
  width: 2px;
  height: 25px; 
  background-color: lightblue;
}

.sunray {
  width: 4px;
  height: 20px; 
  background-color: rgba(255, 255, 0, 0.4);
  position: absolute;
}

.sun {
  width: 100px;
  height: 100px;
  position: absolute;
  background-color: rgb(246, 239, 9);
  opacity: 1;
  z-index: 1;
  border-radius: 50%;
}

.cloud {
  width: 100px;
  height: 50px; 
  position: absolute;
  background-image: url(../../assets/cloud.svg);
  background-size: 100% 100%;
  
}

  .form-full-width{
    width: 100%;
  }



  .relationcontainer{
    width: 65%;
    height: 100%;
    float: left;
    margin: 1px 1px 1px 1px;
    padding: 1px 1px 1px 1px;
    box-sizing: border-box;
  }

.selection-form {
     margin-left: 6em;
   }



.drop-zone{
  min-width: 200px;
  min-height: 150px;
  height: auto;
  max-width: 600px;
  margin: 20px;
  padding: 20px;
  border-style: dashed;
  border-width:5px;  
  margin: 10px;
  word-wrap: break-word;
}

.drop-zone-over {
  min-width: 200px;
  min-height: 150px;
  max-width: 300px;
  margin: 20px;
  padding: 20px;
  border-style: dashed;
  border-width:5px;  
  border-color: red;
  margin: 10px;
}

.righ-upload-block{
  width: 48%;
  display: inline-block;
}

.left-upload-block{
  width: 48%;
  display: inline-block;
}

.card-left-menu{
  margin-right: 10px;
  margin-top: 10px;
}

.mat-snack-bar-container.snackbar-class { 
  background: #2b6ca3; 
  color: white;
}

.selectrelation {
  padding-left: 100px;
  margin-left: 100px;
  height: 30px;
}

.topbanner {
  animation-name: revealtop;
  transition: 0.5s;
  margin-top: -55px;
  height: 65px;
  position:relative;  
  background-color: var(--primary-color);
  border-radius: 0px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.topbanner:hover {
  animation-name: revealtop;
  transition: 0.5s;
  margin-top: 0px;
}

.mat-tab-list {
  .mat-tab-label[_ngcontent-c3] {
    min-width: 72px;
  }
}


.mat-card-enydea {
  padding: 2;
}

.max-expansion-panel{
  width: 100%;
  margin: 10px;
  flex-basis: auto;
}

.max-table-content{
  width: 100%;
}

.mat-expansion-panel-header-title {
  width: 100%;
  flex-basis: auto;
  justify-content: space-between;
  align-items: center;
}


.buttons-container{
  padding-right: 150px;
}

.listview{
  min-height: 300px;
  //height: 100vh;
  //overflow-y: scroll;
  //overflow-x: visible;
  //padding-right: 5px;
  //margin: 10px 5px 10px 0px;
  margin-top: 10px;
}

.mat-tab-body-content {
  overflow-y: hidden!important;
}

.path-edit { /* class name used in javascript too */
  // width: 100%; __<<ngThemingMigrationEscapedComment3>>__
  // height: 100%; __<<ngThemingMigrationEscapedComment4>>__
  position: absolute;
  left: 0;
  top: 0;
  //z-index: -1;
  background-size: cover;
  overflow: visible!important;
  //transform: matrix(1, 0, 0, 1, 0, 0)!important;
  //@extendpointer-events: none;
}

.gridcells {
  //position: absolute;
  border: 1px solid lightgray; 
  margin-right: -1px;
  margin-bottom: -1px;
}

@for $i from 1 through 100{
  .iconsize-#{$i}{
    font-size: $i+unquote('px') !important;
    height: $i+unquote('px') !important;
    width: $i+unquote('px') !important;
  };
}


.listview-noshow{
  min-height: 600px;
  width: 0%;
  //margin: -50%;
  overflow: hidden;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: 0.6s;
  }

.listview-xs-show {
  min-height: 600px;
  width: 50%;
  overflow: hidden;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: 0.6s;
}

.listview-normal {
  min-height: 600px;
  width: 100vh;
  width: 20%;
  display: flex;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: 0.6s;
  margin: 10px; 
}

.container-noshow{
  width: 100%;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: 0.6s;
  }

.container-xs-show {
  width: 100%; // was 50%
  transition: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: 0.6s;
  margin-right: -50%;
}

.container-normal {
  width: 80%;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: 0.6s;
  margin: 10px; 
}

.showprogressbardiv {
  opacity:    0.5; 
  background: #000; 
  width:      100%;
  height:     100%; 
  z-index:    10;
  top:        0; 
  left:       0; 
  position:   fixed; 

  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100vh;
  // height: 100vh;
  // z-index: 10000;
  // background-color: white;
}


.svg-drag-select-area-overlay {
  border: 1px dotted lightblue;
  background-color: rgba(255,255,255,.4);
  z-index: 100000;
}

.data-selected {
  fill: rgba(173, 216, 230, 0.541)!important;
}


.imggreyclass {
  -webkit-filter: grayscale(1); /* Webkit */
  filter: gray; /* IE6-9 */
  filter: grayscale(1); /* W3C */
}

.imgblur {
  filter: blur(4px);
  -moz-filter: blur(4px);
  -webkit-filter: blur(4px);
  -o-filter: blur(4px);
}

.inputbox-50 {
  width: 50%;
}


.inputbox-30 {
  width: 30%;
}

.imgblursection {
  z-index: 1;
  width: calc(100% - 20px);
  // height: calc(100% - 20px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(4px);
}

$color-wing: dodgerblue;
$color-sub-wing: lighten(dodgerblue, 10%);
$color-background: #d6c18b;

$color-wing2: red;
$color-sub-wing2: lighten(red, 10%);
$color-background2: #d6c18b;

.butterfly {
  transform: scale(.3);
  //animation: hover 250ms cubic-bezier(.48,.01,.54,1) infinite;
  //animation-direction: alternate;
  animation-fill-mode: reverse;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(50deg) rotateY(20deg) rotateZ(-50deg) translateY(0px);
  width: 30px;
  
  &::before {
      background: darken($color-background,55%);
      border-radius: 50%;
      content: '';
      display: block;
      height: 110px;
      left: 50%;
      margin-left:-10px;
      outline: 1px solid transparent;
      position: absolute;
      top: -15px;
      transform: rotatey(100deg);
      width: 20px;
      z-index: 2;
  }
}

.wing {
  background: #888;
  display: block;
  opacity: 0.7;
  outline: 1px solid transparent;
  position: absolute;
  top: 0;
  
  &:first-child {
      //animation: leftflap 250ms cubic-bezier(.48,.01,.54,1) infinite;
      //animation-direction: alternate;
      //animation-fill-mode: reverse;
      height: 1px;
      left: 0;
      transform: rotateY(-20deg);
      transform-origin: 700% 50%;
      width: 1px;
      z-index: 3;
  }
  
  &:last-child {
      //animation: rightflap 250ms cubic-bezier(.48,.01,.54,1) infinite;
      //animation-direction: alternate;
      //animation-fill-mode: reverse;
      right:0;
      transform: rotateY(200deg);
      z-index: 1;
  }
  
  .bit {
      background: dodgerblue;
  }
  .bit::after {
      background: #ffffff4b;
  }
  
  .bit, .bit::after {
      border-radius: 50%;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      transform-origin: 100% 50%;
  }
  
  .bit:first-child {
      height: 70px;
      text-align: center;
      top: 15px;
      transform: rotateZ(40deg);
      width: 130px;
      
      &::after {
          content: '';
          display: inline-block;
          height: 60px;
          left: -30px;
          top: 5px;
          width: 100px;
      }
  }
  
  .bit:last-child {
      height: 55px;
      transform: rotateZ(-40deg);
      width: 100px;
      
      &::after {
          content: '';
          display: inline-block;
          height: 45px;
          left: -24px;
          top: 5px;
          width: 60px;
          z-index:1;
      }
  }
}

.speed-dial-container {
  position: fixed;
  bottom: 40px;
  right: 50px;
  z-index: 99;
}

.iframe-container {
  overflow: hidden;
  // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
  padding-top: 56.25%;
  position: relative;
}
 
.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}

.starshooting
{
    position:absolute;
    top:100px;
    left:100px;
    width:285px;
    height:0px;
    border-top:1px solid #fff;
    opacity:.5;
    
}

.counter-animation {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: -1;
}

.counter-container {
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  //line-height: 100%;
}

$shooting-time: 3000ms;

.shooting_star {
  position: absolute;
  left: 50%;
  top: 50%;
  // width: 100px;
  height: 2px;
  background: linear-gradient(-45deg, rgba(95, 145, 255, 1), rgba(0, 0, 255, 0));
  border-radius: 999px;
  filter: drop-shadow(0 0 6px rgba(105, 155, 255, 1));
  // animation:
  //   tail $shooting-time ease-in-out infinite,
  //   shooting $shooting-time ease-in-out infinite;
  
  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    // width: 30px;
    height: 2px;
    background: linear-gradient(-45deg, rgba(0, 0, 255, 0), rgba(95, 145, 255, 1), rgba(0, 0, 255, 0));
    transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    //animation: shining $shooting-time ease-in-out infinite;
  }

  &::after {
    //@extend .shooting_star::before;
    @extend .shooting_star, ::before;
    transform: translateX(50%) rotateZ(-45deg);
  }
  
  @for $i from 1 through 4 {
    &:nth-child(#{$i}) {
      $delay: random(9999) + 0ms;
      top: calc(50% - #{random(400) - 200px});
      left: calc(50% - #{random(300) + 0px});
      animation-delay: $delay;
      // opacity: random(50) / 100 + 0.5;
      
      &::before,
      &::after {
        animation-delay: $delay;
      }
    }
  }
}

@keyframes tail {
  0% {
    width: 0;
  }
  
  30% {
    width: 100px;
  }
  
  100% {
    width: 0;
  }
}

@keyframes shining {
  0% {
    width: 0;
  }
  
  50% {
    width: 30px;
  }
  
  100% {
    width: 0;
  }
}

@keyframes shooting {
  0% {
    transform: translateX(0);
  }
  
  100% {
    transform: translateX(300px);
  }
}

.mat-bottom-sheet-container-medium {
  min-width: 500px;
}


.listshowbutton:hover {
  transition: 0.70s;
  margin: 10px 10px 10px 5px;
  z-index: 12;
}

.listshowbutton {
  transition: 0.70s;
  margin: 10px 10px 10px -10px;
  z-index: 12;
}

.listshowcontainer{
  z-index: 11;
  top: 60%; 
  left: 0;
  position: fixed;
  border-radius: 0 5px 5px 0;
  background-color: #238f9d41;
  overscroll-behavior: contain;
}


.selectBox {
  z-index: 10000;
  border: 1px solid #55aaff;
  background-color: rgba(75, 160, 255, 0.3);
  position: fixed;
}

.elementanimationarrayselected .copypath {
  stroke: blue;
}


//video app styles most by global!!

// global styles
$root-font-size: 10px;
$body-bg-color: #f9f9f9;
$app-bg-color: #ffffff;

//progress bar styles
$progress-bar-height: 0.5rem;
$crtls-height: 4.5rem;
$tooltip-height: 2.5rem;
$general-transition-time: 500ms;
$thumb-height: 10rem;

@mixin full-size {
  height: 100%;
  width: 100%;
  margin: 0;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin hover-pointer {
  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
  }
}

@mixin padding-0-1rem {
  & > * {
    padding: 0 1rem;
  }
}

@mixin dash-border-box {
  border: dashed 1px #979797;
  border-radius: 10px;
  margin-bottom: 10px;
}

@mixin mat-slider-colors($thumb-color, $bar-color) {
  .mat-slider-thumb,
  .mat-slider-track-fill {
    border-color: $thumb-color !important;
    background-color: $thumb-color !important;
  }
  .mat-slider-track-background {
    background-color: $bar-color !important;
  }
}

.display-none {
  display: none;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.c-pointer {
  @include hover-pointer;
}

.closebutton {
  top: 5px; 
  right: 5px; 
  position: absolute; 
  color: white;
}

.close-button{
  float: right;
  top:-24px;
  right:-24px;
}

